import { Store } from 'libx'
import { Checklist } from '../domain/info-gathering/Checklist'
import { DocumentSupportingItemInput } from '../domain/info-gathering/RequestedSupportingItem'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import { timestampToISO } from 'utils/grpcUtil'
import { maybeParseDate } from 'utils/dateUtil'

/**
 * Info gathering store.
 */
export class InfoGatheringStore extends Store {
  /**
   * Info gathering checklists, ID'ed by the job.
   */
  checklists = this.collection({
    model: Checklist,
  })

  constructor({ rootStore }) {
    super({ rootStore })
    this.api = rootStore.api
    this.api.infoGathering.events$.subscribe(this._onRemoteEvent.bind(this))
  }

  /**
   * Get the checklist for a job.
   */
  async fetchChecklist(jobId) {
    const response = await this.api.infoGathering.getChecklist({ jobId })
    return this.checklists.set({
      id: jobId,
      ...mapChecklistProtoToDto(response),
    })
  }

  /**
   * Provides the document to the supporting item.
   *
   * @param jobId
   * @param documentId
   * @param {RequestedSupportingItem} supportingItem
   * @returns {Promise<void>}
   */
  async provideDocument(jobId, documentId, supportingItem) {
    if (!(supportingItem.input instanceof DocumentSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.provideSupportingItemDocument({
      jobId,
      documentId,
      supportingItemId: supportingItem.id,
    })

    supportingItem.input.addDocument(documentId)
  }

  /**
   * Removes the document from the supporting item.
   *
   * @param documentId
   * @param {RequestedSupportingItem} supportingItem
   * @returns {Promise<void>}
   */
  async removeDocument(documentId, supportingItem) {
    if (!(supportingItem.input instanceof DocumentSupportingItemInput)) {
      throw new Error('Invalid supporting item type')
    }

    await this.api.infoGathering.removeSupportingItemDocument({
      documentId,
      supportingItemId: supportingItem.id,
    })

    supportingItem.input.removeDocument(documentId)
  }

  /**
   * Called when the client receives an event from the server.
   *
   * @param event
   * @private
   */
  _onRemoteEvent(event) {
    switch (event.getTypeCase()) {
      case RealtimeEvent.TypeCase.REQUESTED_SUPPORTING_ITEM_UPDATED: {
        return this._onRemoteItemUpdated(
          event.getRequestedSupportingItemUpdated().toObject()
        )
      }
    }
  }

  /**
   * Handles a requested supporting item update from the server.
   * @private
   */
  _onRemoteItemUpdated(event) {
    const checklist = this.checklists.get(event.jobId)
    if (!checklist) {
      return
    }

    const dto = mapItemProtoToDto(event.requestedSupportingItem)
    const item = checklist.getItemById(dto.id)
    if (!item) {
      return
    }

    // Only update if the server timestamp is more recent.
    const lastUpdated = maybeParseDate(dto.update_time)
    if (!item.dateModified || item.dateModified < lastUpdated) {
      item.set(dto, {
        parse: true,
        stripUndefined: true,
      })
    }
  }
}

function mapChecklistProtoToDto(checklistProto) {
  if (!checklistProto) {
    return checklistProto
  }

  return {
    sections: checklistProto.sectionsList.map((sectionProto) => ({
      id: sectionProto.id,
      name: sectionProto.name,
      heading: sectionProto.heading,
      description: sectionProto.description,
      requested_supporting_items: sectionProto.requestedSupportingItemsList.map(
        (itemProto) => mapItemProtoToDto(itemProto)
      ),
    })),
  }
}

function mapItemProtoToDto(itemProto) {
  if (!itemProto) {
    return itemProto
  }

  return {
    id: itemProto.id,
    name: itemProto.name,
    heading: itemProto.heading,
    short_description: itemProto.shortDescription,
    long_description: itemProto.longDescription,
    image_url: itemProto.imageUrl,
    preview_url: itemProto.previewUrl,
    required: itemProto.required,
    completed: itemProto.completed,
    input: mapInputProtoToDto(itemProto.input),
    update_time: timestampToISO(itemProto.updateTime),
    ad_hoc: itemProto.adHoc,
  }
}

function mapInputProtoToDto(inputProto) {
  if (!inputProto) {
    return inputProto
  }

  switch (true) {
    case !!inputProto.document: {
      return {
        type: 'DOCUMENT',
        document_type_short_id: inputProto.document.documentTypeShortId,
        document_ids: inputProto.document.documentIdsList,
      }
    }
  }

  console.error('Unknown supporting item input', inputProto)
  return null
}
