import { Model } from 'libx'
import orderBy from 'lodash/orderBy'
import { computed, observable } from 'mobx'
import { RequestedSupportingItem } from './RequestedSupportingItem'

/**
 * Checklist.
 */
export class Checklist extends Model {
  /**
   * ID of the job that the checklist is for.
   * @type {string}
   */
  @observable id = ''

  /**
   * List of sections.
   * @type {ChecklistSection[]}
   */
  @observable sections = []

  /**
   * Whether the checklist is empty.
   *
   * @returns {boolean}
   */
  @computed
  get isEmpty() {
    return this.allItems.length === 0
  }

  /**
   * Gets a flattened list of all requested supporting items.
   *
   * @returns {RequestedSupportingItem[]}
   */
  @computed
  get allItems() {
    return this.sections.flatMap((s) => s.requestedSupportingItems)
  }

  /**
   * Determine how many items there are in the checklist across all sections.
   */
  @computed
  get itemCount() {
    return this.allItems.length
  }

  /**
   * Determine how many items have been completed.
   */
  @computed
  get completedCount() {
    return this.allItems.filter((x) => x.completed).length
  }

  /**
   * Gets the requested item by ID.
   *
   * @param id ID of the item to get.
   */
  getItemById(id) {
    for (const section of this.sections) {
      for (const item of section.requestedSupportingItems) {
        if (item.id === id) {
          return item
        }
      }
    }

    return null
  }

  parse(json, opts) {
    return {
      id: json.id,
      sections: json.sections?.map((s) =>
        ChecklistSection.fromJSON(s, { rootStore: this.rootStore })
      ),
    }
  }
}

/**
 * A section in a checklist.
 */
export class ChecklistSection extends Model {
  @observable id = ''

  @observable name = ''
  @observable heading = ''
  @observable description = ''

  /**
   * The list of requested supporting items.
   *
   * @type {RequestedSupportingItem[]}
   */
  @observable requestedSupportingItems = []

  /**
   * The count of items in the section.
   *
   * @returns {number}
   */
  @computed
  get itemCount() {
    return this.requestedSupportingItems.length
  }

  /**
   * The count of completed items in the section.
   *
   * @returns {number}
   */
  @computed
  get completedCount() {
    return this.requestedSupportingItems.filter((item) => item.completed).length
  }

  parse(json) {
    const items = json.requested_supporting_items?.map((item) =>
      RequestedSupportingItem.fromJSON(item, {
        rootStore: this.rootStore,
        section: this,
      })
    )

    const orderedItems = orderBy(items, (x) => x.required, 'desc')
    return {
      id: json.id,
      name: json.name,
      heading: json.heading,
      description: json.description,
      requestedSupportingItems: orderedItems,
    }
  }

  static fromJSON(json, opts) {
    return new ChecklistSection(json, {
      ...opts,
      parse: true,
      stripUndefined: true,
    })
  }
}
